<template>
  <div>
    <v-card>
      <v-card-title class="panelheader">Crea Report</v-card-title>
      <v-card-text>
        <v-form ref="createForm">
          <v-container>
            <v-row>
              <v-col cols="3">
                <v-select label="Tipo report" :items="reportTypes" v-model="reportType" item-text="text" return-object :rules="[rules.required]"></v-select>
              </v-col>
              <v-col cols="3">
                <v-autocomplete label="Train" v-model="viaggio" @change="changeViaggio" :items="viaggi" item-text="name" item-value="id" clearable />
              </v-col>
              <v-col cols="3">
                <v-text-field label="Call Id" v-model="callId" :rules="[rules.required]" />
              </v-col>
              <v-col cols="3">
                <v-btn-toggle>
                  <v-btn :color="reportFileType === 'PDF' ? 'red darken-2' : 'grey lighten-1'" @click="reportFileType = 'PDF'">
                    <v-icon large color="white">mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <v-btn :color="reportFileType === 'EXCEL' ? 'green darken-2' : 'grey lighten-1'" @click="reportFileType = 'EXCEL'">
                    <v-icon large color="white">mdi-file-excel-box</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="doReport" color="primary" :title="tLabel('Crea report')">
          {{ tLabel("Crea") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- {{ response }} -->

    <v-card class="my-3" v-if="createPressed === true">
      <v-card-text class="grey lighten-5">
        <v-container>
          <v-row align="center">
            <v-col cols="3" class="text-h6">
              {{ response !== null ? response.fileName : tLabel("Attendi..") }}
            </v-col>
            <v-col cols="2" class="text-body-1">
              {{ response !== null ? response.reportTypeText : "" }}
            </v-col>
            <v-col cols="2" class="text-body-1">
              {{ response !== null ? response.callId : "" }}
            </v-col>
            <v-col cols="2" class="text-body-1">
              {{ response !== null ? response.mto : "" }}
            </v-col>
            <v-col cols="3" v-if="response !== null" class="text-right">
              <v-btn fab outlined small color="primary" @click="downloadReport()" :title="tLabel('Download report')">
                <v-icon>mdi-download</v-icon>
              </v-btn>
              &nbsp;
              <v-btn fab outlined small color="primary" @click="emailFormVisible = !emailFormVisible" :title="tLabel('Prepara email per invio report')">
                <v-icon v-if="emailFormVisible === false">mdi-email-edit</v-icon>
                <v-icon v-else>mdi-email-open</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row align="center" v-if="emailFormVisible">
            <v-col cols="12">
              <v-form ref="sendEmailForm">
                <v-card>
                  <v-card-title class="panelheader">{{ tLabel("Prepara email per invio report") }}</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field label="Oggetto" v-model="response.emailSubject" />
                      </v-col>
                    </v-row>
                    <v-row v-if="checkEmailDestinatari()">
                      <v-col cols="12">
                        <p v-for="(item, i) in Object.keys(response.emailDestinatari)" v-bind:key="i">
                          <v-text-field :label="item" v-model="response.emailDestinatari[item]" />
                        </p>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col cols="12">
                        <v-text-field :label="tLabel('Destinatari')" v-model="response.emailDestinatari['Destinatari']" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea label="Testo" v-model="response.emailBody" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-row align="center" v-if="emailSent === true"
                      ><v-icon class="mr-2" color="success">mdi-check-bold</v-icon><span class="text-body-1 font-weight-bold green--text">Email inviata correttamente!</span></v-row
                    >
                    <v-row align="center" v-else-if="emailSent === false || emailResponse"
                      ><v-icon class="mr-2" color="error">mdi-close-thick</v-icon><span class="text-body-1 font-weight-bold red--text">Errore invio email! {{ emailResponse }}</span></v-row
                    >
                    <v-btn @click="sendMail" color="primary" :title="tLabel('Invia email')" v-if="sendingEmail === false">
                      {{ tLabel("Invia email") }}
                    </v-btn>
                    <v-btn outlined :title="tLabel('Invio in corso..')" v-else>
                      {{ tLabel("In corso..") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FormatMixins from "../../mixins/FormatsMixins";
import ToastMixin from "../../mixins/ToastMixin";

export default {
  mixins: [FormatMixins, ToastMixin],
  data() {
    return {
      reportTypes: [],
      reportType: null,
      callId: null,
      reportFileType: "PDF",
      response: null,
      fileData: null,
      emailFormVisible: false,
      viaggi: [],
      viaggio: {},
      createPressed: false,
      sendingEmail: false,
      emailSent: null,
      emailResponse: null,
    };
  },
  async mounted() {
    this.reportTypes = [
      { value: "distintaCarico", text: "Distinta carico" },
      { value: "vuoti", text: "Report Vuoti" },
    ];
    this.viaggi = await this.$api.get(this.$apiConfiguration.BASE_PATH + "viaggiTreno/listViaggiPartenza");
    this.viaggi.sort((a, b) => {
      const lowA = a.name.toLowerCase();
      const lowB = b.name.toLowerCase();

      return lowA < lowB ? -1 : lowA > lowB ? 1 : 0;
    })
  },
  computed: {
    url() {
      return this.$apiConfiguration.BASE_PATH + "reports/" + (this.reportType !== null ? this.reportType.value : "") + "/" + this.callId + "/" + this.reportFileType;
    },
  },
  methods: {
    checkEmailDestinatari() {
      return this.response.emailDestinatari && this.response.emailDestinatari !== null && JSON.stringify(this.response.emailDestinatari) !== "{}";
    },
    async doReport() {
      if (!this.$refs.createForm.validate()) {
        return;
      }
      this.emailFormVisible = false;
      this.createPressed = true;
      this.sendingEmail = false;
      this.emailSent = null;
      this.emailResponse=null;
      this.response = null;
      try {
        this.response = await this.$api.post(this.url);
        this.response.reportTypeText = this.reportType.text;
        this.response.callId = this.callId;
        if (!this.checkEmailDestinatari()) {
          this.response.emailDestinatari = {
            Destinatari: "",
          };
        }
        const _callConfig = {
          responseType: "blob",
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "*/*",
            Pragma: "no-cache",
          },
        };
        this.fileData = await this.$api.get(this.$apiConfiguration.BASE_PATH + "files/download/" + this.response.fileId, _callConfig);


      } catch (e) {
        this.createPressed = false;
        this.showError(`Errore creazione report! ${e.message}`);
        console.log(e);
      }
    },
    async downloadReport() {
      try {
        const fileURL = window.URL.createObjectURL(new Blob([this.fileData], {}));
        const fileLink = document.createElement("a");
        fileLink.setAttribute("href", fileURL);
        fileLink.setAttribute("download", this.response.fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        this.showError(`Download fallito! ${e.message}`);
        console.log(e);
      }
    },
    async sendMail() {
      this.sendingEmail = true;
      this.emailSent = null;
      if (!this.$refs.sendEmailForm.validate()) {
        return;
      }
      const url = this.$apiConfiguration.BASE_PATH + "reports/" + "send-mail";
      try {
        // aggiunta andate a capo per possibili disclaimer aggiunti lato server
        var responseForMail = JSON.parse(JSON.stringify(this.response));
        responseForMail.emailBody += "<br/><br/>";
        this.emailResponse= await this.$api.post(url, responseForMail);
        this.emailSent =  !this.emailResponse;
      } catch (e) {
        this.emailSent = false;
        console.log(e);
      }
      this.sendingEmail = false;
    },
    async changeViaggio(val) {
      this.callId = null;
      if (val) {
        const viaggio = await this.$api.get(this.$apiConfiguration.BASE_PATH + "viaggiTreno/" + val);
        this.callId = viaggio.codiceEsterno;
      }
    },
  },
};
</script>
